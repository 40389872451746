@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  background-color: rgb(9, 9, 11); /* matches bg-zinc-950 */
  min-height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
} 